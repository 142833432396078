"use client";
import React, { useRef } from "react";
import { motion, useScroll, useTransform } from "framer-motion";

type Props = {
  text: string;
  isDark?: boolean;
};

const Word = (props: Props) => {
  let { text } = props;
  let element = useRef(null);
  const { scrollYProgress } = useScroll({
    target: element,
    offset: ["start .9", "start start"],
  });
  const words = text.split(" ");

  return (
    <p className="flex flex-wrap" ref={element}>
      {words.map((word, i) => {
        const start = i / words.length;
        const end = start + 1 / words.length;

        const opacity = useTransform(scrollYProgress, [start, end], [0, 1]);
        return (
          <span key={i} className="relative">
            <span className="absolute opacity-[0.6]">{word}</span>
            <motion.span
              style={{ opacity: opacity }}
              className={`mr-2 mt-4 ${
                props.isDark ? "text-white" : "text-[#051F20]"
              } `}
              key={i}
            >
              {word}
            </motion.span>
          </span>
        );
      })}
    </p>
  );
};

export default Word;
